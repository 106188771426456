<template lang="pug">
v-card.mr-2.mb-2(width='250')
    v-card-text
        v-row
            v-col
                span.font-weight-bold {{ template.name }}
        v-row
            v-col
                span {{ template.description }}
    v-card-actions
        v-spacer
        v-btn(text, @click='showPreviewDialog = true') Preview
        slot(name='cardActions')
    v-dialog(v-model='showPreviewDialog', max-width='650')
        v-card(light)
            v-card-title Template Preview
            v-card-text
                span(v-html='template.content')
            v-card-actions
                v-spacer
                v-btn(text, @click='showPreviewDialog = false') Close
</template>

<script>
export default {
    props: {
        template: {
            type: Object,
            default () {
                return {};
            }
        }
    },
    data () {
        return {
            showPreviewDialog: false
        };
    }
};
</script>
